
import moment from "moment";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["location", "cart"]),
    isDelivery() {
      return this.cart.handoff === "delivery";
    },
    confirmText() {
      return {
        pickup: "Confirm Pickup Location",
        delivery: "Confirm Delivery Location",
        curbside: "Confirm Curbside Location",
      }[this.cart.handoff];
    },
    locationAddress() {
      const { address, city, state, zip } = this.location;
      return `${address}, ${city}, ${state} ${zip}`;
    },
    deliveryAddress() {
      const { address, city, building } = this.cart?.address;

      return `${address}${building ? ` ${building}` : '' }, ${city}`;
    },
    estimatedReadyTime() {
      const earliestTime = this.cart?.time?.earliest || "";
      const wantedTime = this.cart?.time?.wanted || "";
      const time = this.cart?.time?.mode === 'asap' ? earliestTime : wantedTime;

      const formattedTime = moment(time, "YYYYMMDD HH:mm").format(
        "h:mm A [on] MM/DD/YYYY"
      );
      return formattedTime;
    },
    modalWidth() {
      return this.$mq === "sm" ? "95%" : "600px";
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("location-confirm-modal");
    },
  },
};
