import { render, staticRenderFns } from "./DropdownDeliveryForm.vue?vue&type=template&id=db0d62bc"
import script from "./DropdownDeliveryForm.vue?vue&type=script&lang=js"
export * from "./DropdownDeliveryForm.vue?vue&type=script&lang=js"
import style0 from "./DropdownDeliveryForm.vue?vue&type=style&index=0&id=db0d62bc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormField: require('/opt/build/repo/components/forms/FormField.vue').default,AppButton: require('/opt/build/repo/components/common/AppButton.vue').default})
