
import moment from 'moment'
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(['loading', 'errors', 'token', 'user', 'cart', 'showCart', 'location', 'channel']),
    ...mapState({
      showMenu: (state) => state.app.showMenu,
      cateringDialogue: (state) => state.app.cateringDialogue,
      globals: state => state.app.globals,
    }),
    pagePath() {
      return this.$route.path.replace(/\//g, "_").replace(/_$/, "");
    },
    hasErrors() {
      return this.errors?.length > 0 || this.errors?.messages?.length > 0;
    },
    webViewClass() {
      const pagePath = this.pagePath ? `page_${this.pagePath}` : "";
      return pagePath;
    },
  },
  async beforeMount() {
    if (!Object.keys(this.globals).length) {
      this.getGlobals(this.$nuxt.$store)
    }

    if (this.$route && this.$route.query && this.$route.query.store) {
      if (this.$route.query.store === 'clear') {
        this.setLocation(null)
        this.setMenu(null)
        await this.abandonCart()
      }
    }

    if (this.token && !this.user) {
      await this.getUser();
    }

    if (this.cart) {
      await this.getCart();
    }

    if (this.location) {
      await this.createCart();
    }

    await this.refreshLocation()
  },
  mounted() {
    if (this.$route.query.channel) {
      this.$store.commit('setFilteredMenu', [])
      this.$store.commit('setSearchedMenu', false)
      this.setMenu(null)
      this.setLocation(null)
      if (this.$route.query.channel == 'catering') {
        this.setChannel('catering')
      } else {
        this.setChannel('default')
      }
    }
  },
  methods: {
    ...mapActions(['getUser', 'getCart', 'createCart', 'logoutUser', 'reloadLocation', 'transferCart']),
    ...mapActions({
      getGlobals: 'app/getGlobals'
    }),
    ...mapMutations(['setErrors', 'setChannel', 'setMenu', 'setLocation']),
    ...mapMutations({
      setShowMenu: "app/setShowMenu",
      setShowLocationDropdown: "app/setShowLocationDropdown",
      setCateringDialogue: "app/setCateringDialogue",
    }),
    async refreshLocation() {
      // Do not reload the location data if it is a menu page
      const isMenu = this.$route.path.includes('/menu')
      if (isMenu) return

      // Reload the location data
      if (this.cart) {
        const menuId = this.cart?.menu_id

        if (!menuId) return

        await this.reloadLocation(menuId)

        // Transfer cart if the menus are still different for some reason
        if (this.location?.services?.transfercart && this.cart?.menu_id !== this.location?.menu_id) {
          await this.transferCart(menuId)
        } else {
          await this.clearCart()
        }

        await this.reloadLocation(menuId)
      }
    },
    async clearCart() {
      if (this.cart) {
        const today = new Date()
        const clearCartAt = new Date(this.cart.reset)
        if (moment(clearCartAt).isBefore(moment(today))) {
          if (this.$route.fullPath.includes('/order/checkout')) {
            this.$router.push('/order')
            setTimeout(() => this.setErrors([ 'Your basket timed out, please recreate your order' ]), 1000)
          }
          this.$utils.log('Basket cleared')
          await this.abandonCart()
        }
      }
    },
  },
  watch: {
    "$route.path"(to, from) {
      this.setShowMenu(false)
      this.setShowLocationDropdown(false)
      // this.$store.commit("setShowCart", false)

      if (from == '/catering') {
        if (this.channel == 'default') {
          this.setCateringDialogue(true)
        }
      }
    },
  }
};
