
  import { mapState, mapMutations } from 'vuex'

  import mainMenu from '~/assets/menus/main.json'
  import mobileMenu from '~/assets/menus/mobile.json'
  import mobileBottomMenu from '~/assets/menus/mobile-bottom.json'

  export default {
    computed: {
      mainMenu() {
        return mainMenu
      },
      mobileMenu() {
        return mobileMenu
      },
      mobileBottomMenu() {
        return mobileBottomMenu
      },
      ...mapState(['menu','channel','location']),
      ...mapState({
        showMenu: state => state.app.showMenu,
        hidingMenu: state => state.app.hidingMenu,
      }),
      careersLink() {
        const jobLink = this.location?.c_joblink;

        if (!jobLink) {
          return {
            link: "/careers",
            target: "_self",
            type: "relative",
          };
        }

        const isRelative = jobLink.includes("https://mellowmushroom.com/");

        if (isRelative) {
          return {
            link: jobLink
              .replace(/https:\/\/mellowmushroom\.com\//g, "/")
              .replace("/mellow-jobs/apply/", "/careers"),
            target: "_self",
            type: "relative",
          };
        }

        return {
          link: this.location.c_joblink,
          target: "_blank",
          type: "absolute",
        };
      },
      subMenuCount() {
        return this.mainMenu.columns.flatMap(column => column.links).length
      }
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);

      this.$nextTick(() => {
        document.addEventListener("keydown", this.trapFocus);

        this.$refs.menu.querySelectorAll('a, button')[0].focus();
      });
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
      document.removeEventListener("keydown", this.trapFocus);
    },
    methods: {
      ...mapMutations({
        setShowMenu: 'app/setShowMenu',
        setHidingMenu: 'app/setHidingMenu',
        setCateringDialogue: "app/setCateringDialogue",
      }),
      trapFocus(event) {
        if (event.key === 'Escape') {
          this.toggleMenu();
        }

        const menu = this.$refs.menu;
        const focusableElements = menu.querySelectorAll('a, button');
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (event.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        }

        if (!event.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      },
      isActive(page) {
        if (page == 'catering') {
          if (this.$route.path.split('/')[1] !== 'locations') {
            return this.channel == 'catering'
          }
        }
        if (page == 'order') {
          if (this.channel !== 'catering') {
            return this.$route.path.split('/')[1] == 'menu'
          } else {
            return false
          }
        }
        return page == this.$route.path.split('/')[1]
      },
      startMealtime() {
        if (this.channel == 'catering') {
          this.setCateringDialogue(true)
        } else {
          if (this.location) {
            this.$router.push('/order')
          } else {
            this.$router.push('/menu')
          }
        }
      },
      startCatering() {
        if (this.channel == 'catering') {
          this.$router.push('/order')
        } else {
          this.$router.push('/catering')
        }
      },
      toggleMenu() {
        if (this.showMenu) {
          this.setHidingMenu(true)
          setTimeout(function() {
            this.setShowMenu(false)
            this.setHidingMenu(false)
          }.bind(this), 500)
        } else {
          this.setShowMenu(true)
        }
      },
      handleClickOutside(event) {
        const toggleMenuButton = document.querySelector('#toggle-menu');
        const toggleMenuButtonIcon = document.querySelector('#toggle-menu svg');
        const menuElement = this.$refs.menu;

        const shouldToggleMenu = !menuElement.contains(event.target) && !toggleMenuButton.contains(event.target) && !toggleMenuButtonIcon.contains(event.target);

        if (shouldToggleMenu) {
          this.toggleMenu()
        }
      },
    },
    watch: {
      showMenu(isOpen) {
        const nav = this.$refs.menu;
        if (nav && !isOpen) {
          nav.setAttribute('aria-label', 'Menu closed');
        }
      }
    },
  }
